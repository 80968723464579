/* eslint-disable no-unused-vars */
import React, { lazy, Suspense, useState, useEffect } from 'react';
import axios from 'axios';
import { Helmet } from 'react-helmet';
import parse from 'html-react-parser';
import { Route, Routes, Navigate, useLocation } from 'react-router-dom';
import ProtectedRoutes from './utils/ProtectedRoutes';
import Login from './pages/login/Login';
import PageNotFound from './pages/pageNotFound/PageNotFound';
import {
  URL_INSPIRATION_BOARD,
  URL_LOGIN,
  URL_EDIT,
  URL_ABOUT,
  URL_CONTACT,
  URL_FEEDS,
  URL_HOME,
  URL_EXPERTS,
  URL_FAQ,
  URL_INSPIRATION,
  URL_PAGE_NOT_FOUND,
  URL_TEARMS_AND_CONDITIONS,
  URL_PRIVACY_POLICY,
  URL_ALL_ARTICLES,
  URL_PORTFOLIO,
  URL_PROFILE,
  URL_CATEGORY,
  URL_ALL_BLOGS,
  URL_BLOG,
  URL_ARTICLE,
  URL_QUIZ,
  URL_ESTIMATE,
  URL_CHECKLIST,
  DYNEMIC_PORTFOLIO_ID,
  DYNEMIC_ID,
  URL_EXPERT_CONTECTED,
  URL_PROJECTS,
  URL_TIMELINE,
  URL_PAYMENT_PLAN,
  URL_THANK_YOU
} from './constants/routeConstants';
import {BASE_URL} from "./constants/globalConstant"
import PaymentPlan from './pages/profile/pages/paymentPlan/PaymentPlan';
import Loader from './components/loading/Loader/Loader';
import Layout from './utils/Layout';
import Development from './pages/Development';
import Thankyou from './pages/Thankyou';

const Profile = lazy(() => import('./pages/profile/pages/profile/MyProfile'));
const Quiz = lazy(() => import('./pages/quiz'));
const EstimateCalculator = lazy(() => import('./pages/estimateCalculator/EstimateCalculator'));
const InspirationBoard = lazy(() => import('./pages/profile/pages/inspirationBoard/InspirationBoard'));
const EditProfile = lazy(() => import('./pages/profile/pages/profile/editProfile/EditProfile'));
const Checklist = lazy(() => import('./pages/checklist/Checklist'));
const InspirationBoardItem = lazy(() => import('./pages/profile/pages/inspirationBoardItem/InspirationBoardItem'));
const Home = lazy(() => import('./pages/home'));
const InspirationList = lazy(() => import('./pages/inspiration/InspirationList/InspirationList'));
const Listing = lazy(() => import('./pages/blogs/pages/feeds/Feeds'));
const PortfolioDetails = lazy(() => import('./pages/Expert/pages/portfolioDetails/PortfolioDetails'));
const InspirationProductDetail = lazy(() => import('./pages/inspiration/inspirationProductDetail/InspirationProductDetail'));
const ExpertCategories = lazy(() => import('./pages/Expert/pages/expertCategoriesListing/ExpertCategoriesListing'));
const Contact = lazy(() => import('./pages/contactUs/ContactUs'));
const PrivacyPolicy = lazy(() => import('./pages/privacyPolicy/PrivacyPolicy'));
const About = lazy(() => import('./pages/aboutUs/AboutUs'));
const ExpertDetails = lazy(() => import('./pages/Expert/pages/expertDetails/ExpertDetails'));
const ProjectDetails = lazy(() => import('./pages/profile/pages/projectDetailPage'));
const Faq = lazy(() => import('./pages/faq/Faq'));
const AllArticles = lazy(() => import('./pages/blogs/pages/allArticles/AllArticles'));
const AllBlogs = lazy(() => import('./pages/blogs/pages/allBlogs/AllBlogs'));
const TermsConditions = lazy(() => import('./pages/Terms&Conditions/TermsConditions'));
const ExpertServices = lazy(() => import('./pages/Expert/pages/listing/Listing'));
const BlogDetails = lazy(() => import('./pages/blogs/pages/blogDetails/BlogDetails'));
const TimelineStages = lazy(() => import('./pages/profile/pages/TimelineStages'));
const Projects = lazy(() => import('./pages/profile/pages/Projects'));
const ExpertContacted = lazy(() => import('./pages/profile/pages/expertContacted/ExpertContacted'));

function App() {
  // const [seoData, setSeoData] = useState({
  //   metaData: [`<title> BuildX.Homes || Testing </title>`],
  //   script: ''
  // });
  // const location = useLocation();

  const controlledRoute = [
    { component: Quiz, path: URL_QUIZ, navbar: true, footer: false },
    { component: Profile, path: URL_PROFILE, navbar: true, footer: true },
    { component: EditProfile, path: `${URL_PROFILE}${URL_EDIT}`, navbar: true, footer: false },
    
    { component: InspirationBoard, path: `${URL_PROFILE}${URL_INSPIRATION_BOARD}`, navbar: true, footer: true },
    { component: InspirationBoardItem, path: `${URL_PROFILE}${URL_INSPIRATION_BOARD}${DYNEMIC_ID}`, navbar: true, footer: true },
    { component: ExpertContacted, path: `${URL_PROFILE}${URL_EXPERT_CONTECTED}`, navbar: true, footer: true },
    { component: PaymentPlan, path: `${URL_PROFILE}${URL_PAYMENT_PLAN}`, navbar: true, footer: true },
    {component: Projects,path: `${URL_PROFILE}${URL_PROJECTS}`,navbar: true,footer: false},
    {path: `${URL_PROFILE}${URL_PROJECTS}${DYNEMIC_ID}`,component: ProjectDetails,navbar: true,footer: false,},
    {component: TimelineStages,path: `${URL_PROFILE}${URL_PROJECTS}${DYNEMIC_ID}${URL_TIMELINE}`,navbar: true,footer: false},
    { component: Projects, path: `${URL_PROFILE}${URL_PROJECTS}`, navbar: true, footer: false },
    { path: `${URL_PROFILE}${URL_PROJECTS}${DYNEMIC_ID}`, component: ProjectDetails, navbar: true, footer: false },
    { component: TimelineStages, path: `${URL_PROFILE}${URL_PROJECTS}${DYNEMIC_ID}${URL_TIMELINE}`, navbar: true, footer: false },
  ];

  const publicRoute = [
    // {component:Development,path:URL_ESTIMATE,navbar: true, footer: true},
    { component: Home, path: URL_HOME, navbar: true, footer: true },
    { component: Thankyou, path: `${URL_THANK_YOU}`},
    { component: EstimateCalculator, path: URL_ESTIMATE, navbar: true, footer: true },
    { component: Listing, path: URL_FEEDS, navbar: true, footer: true },
    {component: AllBlogs,path: `${URL_FEEDS}${URL_ALL_BLOGS}`,navbar: true,footer: true},
    {component: BlogDetails,path: `${URL_BLOG}${DYNEMIC_ID}`,navbar: true,footer: true},
    {component: BlogDetails,path: `${URL_ARTICLE}${DYNEMIC_ID}`,navbar: true,footer: true,},
    { component: Login, path: URL_LOGIN, navbar: true, footer: false },
    {component: PortfolioDetails,path: `${URL_EXPERTS}${DYNEMIC_ID}${URL_PORTFOLIO}${DYNEMIC_PORTFOLIO_ID}`,navbar: true,footer: true,},
    {component: InspirationProductDetail,path: `${URL_INSPIRATION}${DYNEMIC_ID}`,navbar: true,footer: true,},
    {component: InspirationList,path: URL_INSPIRATION,navbar: true,footer: true},
    {component: AllArticles,path: `${URL_FEEDS}${URL_ALL_ARTICLES}`,navbar: true,footer: true},
    // { component: Faq, path: URL_FAQ, navbar: true, footer: true },
    {component: PrivacyPolicy,path: URL_PRIVACY_POLICY,navbar: true,footer: true,},
    { component: About, path: URL_ABOUT, navbar: true, footer: true},
    {component: TermsConditions,path: URL_TEARMS_AND_CONDITIONS,navbar: true,footer: true},
    { component: Contact, path: URL_CONTACT, navbar: true, footer: false },
    { component: ExpertServices, path: URL_EXPERTS, navbar: true, footer: true },
    { component: ExpertCategories, path: `${URL_EXPERTS}${URL_CATEGORY}`, navbar: true, footer: true },
    { component: ExpertDetails, path: `${URL_EXPERTS}${DYNEMIC_ID}`, navbar: true, footer: true },
    { component: Checklist, path: URL_CHECKLIST, navbar: true, footer: true },
    {component: PageNotFound,path: URL_PAGE_NOT_FOUND,navbar: false,footer: false},
  ];



  const [seoData, setSeoData] = useState({
    metaData: [`<title> BuildX.Homes </title>`],
    script: ''
  });

  const location = useLocation();

  const decodeHtml = (html) => {
    const textArea = document.createElement("textarea");
    textArea.innerHTML = html;
    return textArea.value;
  };

  const cleanHtml = (html) => {
    let cleaned = html.replace(/<\/?p>/g, '');
    cleaned = cleaned.replace(/<br>/g, '');
    cleaned = cleaned.replace(/<\/?span[^>]*>/g, '');
    cleaned = cleaned.replace(/<style[^>]*>.*?<\/style>/g, '');
    cleaned = decodeHtml(cleaned);
    return cleaned;
  };

  const getDefaultSeoData = (pageName) => {
    return {
      metaData: [`<title> BuildX.Homes || ${pageName} </title>`],
      script: ''
    };
  };

  const fetchPageDetails = (pageName) => {
    const url = `${BASE_URL}/public/meta/getMetaByPage/${pageName || 'home'}`;
    axios.get(url)
      .then((res) => {
        const { data } = res;
        if (data.statusCode === 200 && data.statusMessage === "Success" && data.response) {
          const { response } = data;
          if (response.isPublished && response.publishedData && response.slug === pageName) {
            const { metaDetails, scripts } = response.publishedData;
            console.log(response.publishedData);
            const cleanedMetaDetails = metaDetails ? cleanHtml(metaDetails) : '';
            const cleanedScripts = scripts ? cleanHtml(scripts) : '';
            console.log(cleanedMetaDetails, "metacleaned");
            setSeoData({
              metaData: cleanedMetaDetails ? [cleanedMetaDetails] : [],
              script: cleanedScripts
            });
          } else {
            setSeoData(getDefaultSeoData(pageName)); // Use default data if API response is not as expected
          }
        } else {
          setSeoData(getDefaultSeoData(pageName)); // Use default data if API call fails
        }
      })
      .catch((err) => {
        console.error('Error fetching data:', err);
        setSeoData(getDefaultSeoData(pageName)); // Use default data if there is an error
      });
  };

  useEffect(() => {
    const fullPath = location.pathname + location.search;
    console.log("Full Path:", fullPath);
    const pathSegments = fullPath.split('/').filter(segment => segment);
    console.log(pathSegments);
    // const pageName = pathSegments.length > 0 ? pathSegments[pathSegments.length - 1] : 'home';
    const pageName = pathSegments.length > 0 ? pathSegments.join('/') : 'home';
    console.log("Page Name:", pageName);
    fetchPageDetails(pageName);
  }, [location.pathname]);

  useEffect(() => {
    console.log('Final SEO data:', seoData);
  }, [seoData]);

  return (
    <><Helmet>
      {seoData.metaData.map((tag, index) => parse(tag))}
      <script type="application/ld+json">{seoData.script}</script>
    </Helmet>
    <Routes>
        <Route element={<ProtectedRoutes />}>
          {controlledRoute.map((item) => (
            <Route
              key={item.path}
              exact
              path={item.path}
              element={<Suspense fallback={<Loader />}>
                <Layout navbar={item.navbar} footer={item.footer}>
                  <item.component />
                </Layout>
              </Suspense>} />
          ))}
        </Route>

        {publicRoute.map((item) => (
          <Route
            key={item.path}
            exact
            path={item.path}
            element={<Suspense fallback={<Loader />}>
              <Layout navbar={item.navbar} footer={item.footer}>
                <item.component />
              </Layout>
            </Suspense>} />
        ))}

        <Route path="*" element={<Navigate to={URL_PAGE_NOT_FOUND} replace />} />
      </Routes></>
  );
}

export default App;
